<template>
  <div>
    <header>
      <ul>
        <li>
          <img @click="tui" src="../../assets/arrow_left.png" class="img1" alt="" />
        </li>
        <li></li>
        <li>
          <button class="fabu" @click="send()">发布</button>
        </li>
      </ul>
    </header>
    <div class="biao">
        <input v-model="title" type="text" placeholder="标题">
    </div>
 <van-field
  rows="2"
  autosize
  v-model="text_area"
  type="textarea"
    placeholder="添加补充说明，可以获得更好的回答"
  show-word-limit
/>
  </div>
</template>

<script>
import { Toast } from 'vant';
import {commit_wt} from '@/api/QA.js'
import tool from '@/utils/tools.js'
export default {
  data() {
    return {
      title:"",
      text_area:""
    };
  },
  methods: {
    tui() {
      if (tool.isInApp()) {
        if (tool.isIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('app')
        } else {
          this.$jsbridge.callHandler(
                  'vue_backApp'
                  , '返回'
                  , function(responseData) {
                    // android 返参数接受
                  }
          )
        }
      } else {
        this.$router.go(-1)
      }
    },
    send(){
      if(this.title == ''){
        Toast('请填写标题');
      }else if(this.text_area == ''){
        Toast('请填写内容');
      }
      var params = {
        lesson_id:this.$route.query.lesson_id,
        title:this.title,
        content:this.text_area,
        type:1,
      }
      commit_wt(params).then(res=>{
        if(msg == ''){

        }
      })  
    },
  },
};
</script>

<style lang="scss" scoped>
.biao{
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #ccc;
    // background: seagreen;
    input{
        width: 90%;
        height: 100%;
        font-size: 40px;
        margin-left: 30px;
        font-weight: 700;
        color: rgb(0, 0, 0);
    }
}
header {
  width: 100%;
  height: 110px;
  background: #2b2a2f;
  border-bottom: 1px solid rgb(204, 197, 197);
  ul {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(0, 0, 0);
    font-size: 40px;
    text-align: center;
    li {
      width: 260px;
      height: 60px;
      margin-top: 60px;
    }
  }
}
.fabu {
  width: 150px;
  height: 60px;
  line-height: 50px;
  background: #2b2a2f;
  border: 3px solid #fff;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  font-size: 33px;
}
.img1{
    margin-left: -130px;
    width: 36px;
    height: 51px;
    margin-top: 5px;
}
</style>
