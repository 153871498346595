import request from '../utils/request'

export function get_wd_allMsg(params) {
  return request({
    url: '/educlass/Lessonqa/all',
    method: 'GET',
    params
  })
}
export function commit_wt(params) {
  return request({
    url: '/educlass/Lessonqa/saveQuestion',
    method: 'GET',
    params
  })
}
export function get_myWT(params) {
  return request({
    url: '/educlass/Lessonqa/mine',
    method: 'GET',
    params
  })
}
